@import "@/styles/_variables.scss";

















































































































































































































































































































































































.tooltip {
  color: #e69393;
}

.new-btn {
  padding: 0 20px;
}
